<template>
  <div class="wrapper">
    <el-row class="main">
      <aside class="menu-expanded" v-show="!collapsed">
        <!--导航菜单-->
        <div class="menu">
          <el-collapse v-model="activeNames" @change="handleChange">
            <el-collapse-item title="" name="1">
              <template slot="title"
                ><div class="subTit">
                  <el-icon class="el-icon-files"></el-icon>快捷菜单
                </div></template
              >
              <ul class="menuList">
                <li
                  @click="searchCus(item.val, 'quick')"
                  v-for="item in menuList"
                  :class="actived == item.val ? 'actived' : ''"
                  :key="item.val"
                >
                  {{ item.name }}
                </li>
              </ul>
            </el-collapse-item>
            <el-collapse-item title="" name="2">
              <template slot="title"
                ><div class="subTit">
                  <el-icon class="el-icon-collection-tag"></el-icon>订阅搜索
                </div></template
              >
              <ul class="menuList">
                <li
                  @click="searchCus(item.id, 'order')"
                  v-for="item in OrderSearchList"
                  :class="actived == item.id ? 'actived' : ''"
                  :key="item.id"
                >
                  {{ item.name }}
                  <div class="closed" @click.stop="delSearch(item.id)">
                    &times;
                  </div>
                </li>
              </ul>
            </el-collapse-item>
            <el-collapse-item title="" name="3">
              <template slot="title"
                ><div class="subTit">
                  <el-icon class="el-icon-folder-opened"></el-icon>文件夹
                  <div class="edit" @click.stop="editFavorite">编辑</div>
                </div></template
              >
              <el-collapse v-model="activeNames1">
                <el-collapse-item
                  name="1"
                  v-if="myFileData && myFileData.length > 0"
                >
                  <template slot="title"
                    ><div class="subTit_wj">
                      <i class="iconfont icon-lnav_wdwj"></i>我的文件夹
                    </div></template
                  >
                  <ul class="menuList">
                    <li
                      @click="searchCus(item.id, 'folder')"
                      v-for="item in myFileData"
                      :class="actived == item.id ? 'actived' : ''"
                      :key="item.id"
                    >
                      {{ item.name }}
                    </li>
                  </ul>
                </el-collapse-item>
                <el-collapse-item
                  name="2"
                  v-if="shareData && shareData.length > 0"
                >
                  <template slot="title"
                    ><div class="subTit_wj">
                      <i class="iconfont icon-rnav_gxwj"></i>共享文件夹
                    </div></template
                  >
                  <ul class="menuList">
                    <li
                      @click="searchCus(item.id, 'folder')"
                      v-for="item in shareData"
                      :class="actived == item.id ? 'actived' : ''"
                      :key="item.id"
                    >
                      {{ item.name }}
                    </li>
                  </ul>
                </el-collapse-item>
              </el-collapse>
            </el-collapse-item>
          </el-collapse>
        </div>
      </aside>
      <section
        class="content-container clearfix scollDom"
        :class="collapsed ? 'screen' : ''"
      >
        <el-col :span="24">
          <el-row class="searchBox">
            <el-form @submit.native.prevent :model="filter" ref="filter">
              <div class="fl searchL">
                <el-form-item label style="margin-bottom: 20px">
                  <el-input
                    class="search"
                    v-model="filter.filter"
                    @keyup.enter.native="submitForm('filter')"
                    placeholder="请输入项目名称 / 项目经理 /参与者/ 公司名称，支持模糊搜索"
                  >
                    <el-button
                      type="primary"
                      slot="append"
                      @click="submitForm('filter')"
                      >搜 索</el-button
                    >
                  </el-input>
                </el-form-item>
              </div>
              <div class="other">
                <span class="order" @click="orderSearch">订阅搜索</span>
                <span class="more" @click="showMore = !showMore"
                  >{{ showMore ? "收起搜索" : "展开搜索"
                  }}<el-icon
                    :class="
                      showMore ? 'el-icon-arrow-up' : 'el-icon-arrow-down'
                    "
                  ></el-icon>
                </span>
              </div>
            </el-form>
            <div class="searchM" v-show="showMore">
              <el-form
                @submit.native.prevent
                :model="filter"
                ref="filter"
                class="clearfix"
                label-width="110px"
              >
                <el-form-item label="项目ID：" prop="id">
                  <el-input
                    placeholder="请输入项目ID,精确查询"
                    type="number"
                    v-model.number.trim="filter.id"
                    @keyup.enter.native="submitForm('filter')"
                    class="searchInput numberHeight"
                    style="width: 240px"
                  >
                    <template slot="suffix">
                      <el-icon
                        class="el-icon-search"
                        @click.native="submitForm('filter')"
                      ></el-icon>
                    </template>
                  </el-input>
                </el-form-item>
                <el-form-item label="状态：" prop="statusList">
                  <el-select
                    v-model="filter.statusList"
                    placeholder="请选择"
                    style="width: 240px"
                    clearable
                    multiple
                    filterable
                    collapse-tags
                    :disabled="filter.ownProject"
                    @change="submitForm('filter')"
                  >
                    <el-option
                      v-for="(item, index) in projectType"
                      :key="'a_' + index"
                      :value="item.value"
                      :label="item.value"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="项目经理：" prop="managerIds">
                  <el-select
                    v-model="filter.managerIds"
                    placeholder="请选择"
                    style="width: 240px"
                    clearable
                    filterable
                    multiple
                    collapse-tags
                    @change="submitForm('filter')"
                  >
                    <el-option
                      v-for="i in userList"
                      :key="i.id"
                      :label="i.name"
                      :value="i.id"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="参与者：" prop="participantIds">
                  <el-cascader
                    class="searchCascader"
                    style="min-width: 240px"
                    filterable
                    clearable
                    :options="options2"
                    collapse-tags
                    :show-all-levels="false"
                    v-model="participantIds"
                    :props="props"
                    placeholder="请选择小类"
                    ref="dataRef"
                    @change="getSearch"
                    :filter-method="filterFun"
                  >
                  </el-cascader>
                </el-form-item>
                <el-form-item label="创建时间：" prop="createdStartTime">
                  <el-date-picker
                    style="width: 240px"
                    v-model="timeVal"
                    value-format="yyyy-MM-dd"
                    @change="getTime"
                    type="daterange"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                  >
                  </el-date-picker>
                </el-form-item>
                <el-form-item label="项目操作时间：" prop="createdStartTime">
                  <el-date-picker
                    style="width: 240px"
                    v-model="progectTimeVal"
                    value-format="yyyy-MM-dd"
                    @change="getProgectTime"
                    type="daterange"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                  >
                  </el-date-picker>
                </el-form-item>
                <el-form-item label="城市：" prop="cityIds">
                  <el-cascader
                    style="min-width: 240px"
                    class="fromInput"
                    v-model="filter.cityIds"
                    collapse-tags
                    filterable
                    clearable
                    :options="cityList"
                    :props="{
                      value: 'id',
                      label: 'name',
                      children: 'children',
                      multiple: true,
                      checkStrictly: true,
                    }"
                    @change="getSearch"
                    placeholder="请选择"
                  ></el-cascader>
                </el-form-item>
              </el-form>
            </div>
            <el-col class="table-top">
              <el-button
                type="primary"
                size="mini"
                @click="addNew(false)"
                id="addProject"
              >
                <i class="el-icon-plus"></i> 新增项目
              </el-button>
              <el-dropdown
                class="mgl10"
                trigger="click"
                @command="checkContral($event)"
                v-show="multipleSelection && multipleSelection.length > 0"
              >
                <el-button type="default" plain size="mini">
                  批量操作<i class="el-icon-arrow-down el-icon--right"></i>
                </el-button>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item :command="1" @click.native="addToFavorite"
                    >加入文件夹</el-dropdown-item
                  >
                  <el-dropdown-item
                    :command="2"
                    v-has="'progream:index:setUpSecret'"
                    >设为保密项目</el-dropdown-item
                  >
                  <el-dropdown-item
                    :command="3"
                    v-has="'progream:index:setUpSecret'"
                    >取消保密项目</el-dropdown-item
                  >
                </el-dropdown-menu>
              </el-dropdown>
              <el-button
                type="default"
                plain
                size="mini"
                @click.native="downExport"
                style="margin: 0 10px"
              >
                导出
              </el-button>
              <el-button
                type="default"
                plain
                size="mini"
                class="mgl10"
                @click="removeFavorite"
                v-if="isShowDelBtn"
                :disabled="isDisableDel"
                >从文件夹移出</el-button
              >
              <el-popover
                placement="bottom-start"
                title=""
                width="200"
                trigger="click"
                popper-class="configer3"
                v-model="isOpen"
              >
                <div class="select_check">
                  <el-checkbox-group v-model="checkList">
                    <el-checkbox
                      v-for="(item, index) in headers"
                      :label="item.prop"
                      :value="item.prop"
                      :key="index"
                      >{{ item.label }}
                    </el-checkbox>
                  </el-checkbox-group>
                  <div class="kpiButton">
                    <el-button
                      size="mini"
                      style="margin-right: 15px"
                      type="primary"
                      :disabled="checkList.length == 0"
                      @click="confirm"
                      >应用
                    </el-button>
                  </div>
                </div>
                <el-button slot="reference" size="mini" @click="open"
                  ><i class="el-icon-s-tools"></i>
                </el-button>
              </el-popover>
              <el-pagination
                style="padding: 0"
                :hide-on-single-page="filter.page.total > 10 ? false : true"
                v-if="filter.page.total > 0"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="filter.page.current"
                :page-size="filter.page.size"
                :pager-count="5"
                layout="total,prev, pager, next,sizes"
                :total="filter.page.total"
                :page-sizes="[10, 20, 50, 100]"
              ></el-pagination>
            </el-col>
          </el-row>
          <el-row :gutter="24">
            <el-col :span="24" style="margin-bottom: 10px">
              <el-table
                :header-cell-style="{
                  'background-color': '#f5f7fa',
                  height: '35px',
                  'border-top': '1px #e5e5e5 solid',
                }"
                v-horizontal-scroll
                :data="ListData"
                v-sticky="{ top: 0, parent: '.scollDom' }"
                class="tabBorder custor"
                v-loading="listLoading"
                ref="handSelectTest_multipleTable"
                @selection-change="handleSelectionChange"
                :key="kd"
              >
                <el-table-column
                  type="selection"
                  fixed="left"
                  width="40"
                  align="center"
                ></el-table-column>
                <template v-for="h in tableHeaders">
                  <el-table-column
                    v-if="h.prop == 'id'"
                    width="120"
                    prop="id"
                    class-name="canEdit"
                  >
                    <template slot="header" slot-scope="scope">
                      <span class="header-label" id="id">项目ID</span>
                      <div
                        class="sortL"
                        :class="{
                          ascing: sortType == 'id' && sortCheck == 1,
                          descing: sortType == 'id' && sortCheck == 2,
                        }"
                      >
                        <i class="asc" @click.stop="sortWay('asc', 'id')"></i>
                        <i class="desc" @click.stop="sortWay('desc', 'id')"></i>
                      </div>
                      <el-popover
                        placement="bottom"
                        title="搜索"
                        popper-class="popperEdit"
                        trigger="click"
                      >
                        <i
                          slot="reference"
                          class="heardSearch el-icon-search"
                        ></i>
                        <div class="popcon">
                          <div class="popcon-input">
                            <el-input v-model.number="filter.id" size="mini">
                              <el-button
                                @click="submitForm('filter')"
                                slot="append"
                                icon="el-icon-search"
                              ></el-button>
                            </el-input>
                          </div>
                        </div>
                      </el-popover>
                    </template>
                    <template slot-scope="scope">
                      <span class="tabHref" @click="detailsPop(scope.row.id)">{{
                        scope.row.id
                      }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column
                    v-else-if="h.prop == 'projectName'"
                    prop="projectName"
                    min-width="300"
                    align="left"
                    class-name="canEdit"
                  >
                    <template slot="header" slot-scope="scope">
                      <span class="header-label" id="projectName"
                        >项目名称</span
                      >
                      <el-popover
                        title="搜索"
                        popper-class="popperEdit"
                        placement="bottom"
                        trigger="click"
                      >
                        <i
                          slot="reference"
                          class="heardSearch el-icon-search"
                        ></i>
                        <div class="popcon">
                          <div class="popcon-input">
                            <el-input size="mini" v-model="filter.name">
                              <el-button
                                slot="append"
                                icon="el-icon-search"
                                @click="submitForm('filter')"
                              ></el-button>
                            </el-input>
                          </div>
                        </div>
                      </el-popover>
                    </template>
                    <template slot-scope="scope">
                      <div>
                        <span
                          v-if="scope.row.canView"
                          class="tabHref"
                          :title="
                            scope.row.projectName.length > 20
                              ? scope.row.projectName
                              : ''
                          "
                          @click="detailsPop(scope.row.id)"
                          id="projectName"
                          >{{ scope.row.projectName | caluTitle(20) }}
                        </span>
                        <el-tooltip content="无法查看保密项目">
                          <span
                            style="cursor: pointer"
                            v-if="!scope.row.canView"
                            >********</span
                          >
                        </el-tooltip>

                        <span class="secureIcon" v-if="scope.row.secure"
                          >保密</span
                        ><i
                          v-if="scope.row.canView"
                          @click="showProEdit(scope.row.id, 1)"
                          id="projectEdit"
                          class="el-icon-edit tabHref"
                        ></i>
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column
                    v-else-if="h.prop == 'managerName'"
                    prop="managerName"
                    align="left"
                    class-name="canEdit"
                    min-width="140"
                  >
                    <template slot="header" slot-scope="scope">
                      <span class="header-label" id="managerName"
                        >项目经理</span
                      >
                      <el-popover
                        placement="bottom"
                        title="搜索"
                        popper-class="popperEdit"
                        trigger="click"
                      >
                        <i
                          slot="reference"
                          class="heardSearch el-icon-search"
                        ></i>
                        <div class="popcon">
                          <div class="popcon-input">
                            <el-select
                              v-model="filter.createUserIds"
                              clearable
                              filterable
                              @change="submitForm('filter')"
                              placeholder="请选择"
                            >
                              <el-option
                                v-for="i in userList"
                                :key="i.id"
                                :label="i.name"
                                :value="i.id"
                              >
                              </el-option>
                            </el-select>
                          </div>
                        </div>
                      </el-popover>
                    </template>
                    <template slot-scope="scope">
                      {{ scope.row.managerName }}
                    </template>
                  </el-table-column>
                  <el-table-column
                    v-else-if="h.prop == 'participants'"
                    prop="participants"
                    align="left"
                    class-name="canEdit"
                    min-width="260"
                    show-overflow-tooltip
                  >
                    <template slot="header" slot-scope="scope">
                      <span class="header-label" id="participants"
                        >参与顾问</span
                      >
                      <el-popover
                        placement="bottom"
                        title="搜索"
                        popper-class="popperEdit"
                        trigger="click"
                      >
                        <i
                          slot="reference"
                          class="heardSearch el-icon-search"
                        ></i>
                        <div class="popcon">
                          <div class="popcon-input">
                            <el-select
                              v-model="filter.participantIds"
                              clearable
                              filterable
                              multiple
                              @change="submitForm('filter')"
                              placeholder="请选择"
                            >
                              <el-option
                                v-for="i in userList"
                                :key="i.id"
                                :label="i.name"
                                :value="i.id"
                              >
                              </el-option>
                            </el-select>
                          </div>
                        </div>
                      </el-popover>
                    </template>
                    <template slot-scope="scope">
                      {{ scope.row.participants }}
                    </template>
                  </el-table-column>
                  <el-table-column
                    v-else-if="h.prop == 'bdManagerName'"
                    prop="bdManagerName"
                    align="left"
                    class-name="canEdit"
                    min-width="140"
                  >
                    <template slot="header" slot-scope="scope">
                      <span class="header-label" id="bdManagerName"
                        >项目BD</span
                      >
                      <el-popover
                        placement="bottom"
                        title="搜索"
                        popper-class="popperEdit"
                        trigger="click"
                      >
                        <i
                          slot="reference"
                          class="heardSearch el-icon-search"
                        ></i>
                        <div class="popcon">
                          <div class="popcon-input">
                            <el-select
                              v-model="filter.newprojectManagerId"
                              clearable
                              filterable
                              multiple
                              @change="submitForm('filter')"
                              placeholder="请选择"
                            >
                              <el-option
                                v-for="i in userList"
                                :key="i.id"
                                :label="i.name"
                                :value="i.id"
                              >
                              </el-option>
                            </el-select>
                          </div>
                        </div>
                      </el-popover>
                    </template>
                    <template slot-scope="scope">
                      {{ scope.row.bdManagerName }}
                    </template>
                  </el-table-column>
                  <el-table-column
                    v-else-if="h.prop == 'cities'"
                    prop="cities"
                    align="left"
                    class-name="canEdit"
                    min-width="200"
                  >
                    <template slot="header" slot-scope="scope">
                      <span class="header-label" id="cities">城市</span>
                      <el-popover
                        placement="bottom"
                        title="搜索"
                        popper-class="popperEdit"
                        trigger="click"
                      >
                        <i
                          slot="reference"
                          class="heardSearch el-icon-search"
                        ></i>
                        <div class="popcon">
                          <div class="popcon-input">
                            <el-cascader
                              style="min-width: 240px"
                              class="fromInput"
                              v-model="filter.cityIds"
                              collapse-tags
                              filterable
                              clearable
                              :options="cityList"
                              :props="{
                                value: 'id',
                                label: 'name',
                                children: 'children',
                                multiple: true,
                                checkStrictly: true,
                              }"
                              @change="getSearch"
                              placeholder="请选择"
                            ></el-cascader>
                          </div>
                        </div>
                      </el-popover>
                    </template>
                    <template slot-scope="scope">
                      {{ scope.row.cities }}
                    </template>
                  </el-table-column>
                  <el-table-column
                    v-else-if="h.prop == 'monthlySalary'"
                    prop="monthlySalary"
                    align="left"
                    class-name="canEdit"
                    min-width="140"
                  >
                    <template slot="header" slot-scope="scope">
                      <span class="header-label" id="monthlySalary">月薪</span>
                    </template>
                    <template slot-scope="scope">
                      {{
                        scope.row.monthlySalary ? scope.row.monthlySalary : "-"
                      }}
                    </template>
                  </el-table-column>
                  <el-table-column
                    v-else-if="h.prop == 'companyName'"
                    prop="companyName"
                    align="left"
                    class-name="canEdit"
                    min-width="300"
                  >
                    <template slot="header" slot-scope="scope">
                      <span class="header-label" id="companyName"
                        >客户公司</span
                      >
                    </template>
                    <template slot-scope="scope">
                      {{ scope.row.companyName }}
                    </template>
                  </el-table-column>
                  <el-table-column
                    v-else-if="h.prop == 'countResumePosition'"
                    prop="countResumePosition"
                    min-width="120"
                    class-name="canEdit"
                  >
                    <template slot="header" slot-scope="scope">
                      <span class="header-label" id="countResumePosition"
                        >自动匹配人才</span
                      >
                    </template>
                    <template slot-scope="scope">
                      <span
                        :class="scope.row.countResumePosition ? 'tabHref' : ''"
                        v-if="hasMenuPage('progream:automaticMatching')"
                        @click="detailsPop(scope.row.id, 'automatic')"
                        >{{ scope.row.countResumePosition }}</span
                      >
                    </template>
                  </el-table-column>
                  <el-table-column
                    v-else-if="h.prop == 'createdTime'"
                    prop="createdTime"
                    width="120"
                    align="left"
                    class-name="canEdit"
                  >
                    <template slot="header" slot-scope="scope">
                      <span class="header-label" id="createdTime"
                        >创建时间</span
                      >
                      <div
                        class="sortL"
                        :class="{
                          ascing: sortType == 'createdTime' && sortCheck == 1,
                          descing: sortType == 'createdTime' && sortCheck == 2,
                        }"
                        @click.stop="
                          sortWay(
                            sortType == 'createdTime' &&
                              sortCheck &&
                              sortCheck == 2
                              ? 'asc'
                              : 'desc',
                            'createdTime'
                          )
                        "
                      >
                        <i class="asc"></i>
                        <i class="desc"></i>
                      </div>
                      <el-popover
                        placement="bottom"
                        title="搜索"
                        popper-class="popperEdit"
                        trigger="click"
                      >
                        <i
                          slot="reference"
                          class="heardSearch el-icon-search"
                        ></i>
                        <div class="popcon">
                          <div class="popcon-input">
                            <el-date-picker
                              v-model="timeVal"
                              type="daterange"
                              value-format="yyyy-MM-dd"
                              range-separator="至"
                              start-placeholder="开始日期"
                              end-placeholder="结束日期"
                              @change="submitForm('filter')"
                            >
                            </el-date-picker>
                          </div>
                        </div>
                      </el-popover>
                    </template>
                  </el-table-column>
                  <el-table-column
                    v-else-if="h.prop == 'lastOperateTime'"
                    prop="lastOperateTime"
                    width="200"
                    align="left"
                    class-name="canEdit"
                  >
                    <template slot="header" slot-scope="scope">
                      <span class="header-label" id="lastOperateTime"
                        >我的操作时间</span
                      >
                      <div
                        class="sortL"
                        :class="{
                          ascing:
                            sortType == 'lastOperateTime' && sortCheck == 1,
                          descing:
                            sortType == 'lastOperateTime' && sortCheck == 2,
                        }"
                        @click.stop="
                          sortWay(
                            sortType == 'lastOperateTime' &&
                              sortCheck &&
                              sortCheck == 2
                              ? 'asc'
                              : 'desc',
                            'lastOperateTime'
                          )
                        "
                      >
                        <i class="asc"></i>
                        <i class="desc"></i>
                      </div>
                      <el-popover
                        placement="bottom"
                        title="搜索"
                        popper-class="popperEdit"
                        trigger="click"
                      >
                        <i
                          slot="reference"
                          class="heardSearch el-icon-search"
                        ></i>
                        <div class="popcon">
                          <div class="popcon-input">
                            <el-date-picker
                              v-model="rangTime"
                              type="daterange"
                              value-format="yyyy-MM-dd"
                              range-separator="至"
                              start-placeholder="开始日期"
                              end-placeholder="结束日期"
                              @change="submitForm('filter')"
                            >
                            </el-date-picker>
                          </div>
                        </div>
                      </el-popover>
                    </template>
                    <template slot-scope="scope">
                      {{ scope.row.lastOperateTime }}
                      <span
                        v-if="scope.row.operateGap"
                        class="tag tag02"
                        style="margin-left: 8px"
                        >{{ scope.row.operateGap }}</span
                      >
                    </template>
                  </el-table-column>
                  <el-table-column
                    v-else-if="h.prop == 'maxProjectOperateTime'"
                    prop="maxProjectOperateTime"
                    width="200"
                    align="left"
                    class-name="canEdit"
                  >
                    <template slot="header" slot-scope="scope">
                      <span class="header-label" id="maxProjectOperateTime"
                        >项目操作时间</span
                      >
                      <div
                        class="sortL"
                        :class="{
                          ascing:
                            sortType == 'maxProjectOperateTime' &&
                            sortCheck == 1,
                          descing:
                            sortType == 'maxProjectOperateTime' &&
                            sortCheck == 2,
                        }"
                        @click.stop="
                          sortWay(
                            sortType == 'maxProjectOperateTime' &&
                              sortCheck &&
                              sortCheck == 2
                              ? 'asc'
                              : 'desc',
                            'maxProjectOperateTime'
                          )
                        "
                      >
                        <i class="asc"></i>
                        <i class="desc"></i>
                      </div>
                      <el-popover
                        placement="bottom"
                        title="搜索"
                        popper-class="popperEdit"
                        trigger="click"
                      >
                        <i
                          slot="reference"
                          class="heardSearch el-icon-search"
                        ></i>
                        <div class="popcon">
                          <div class="popcon-input">
                            <el-date-picker
                              v-model="progectTimeVal"
                              type="daterange"
                              value-format="yyyy-MM-dd"
                              range-separator="至"
                              start-placeholder="开始日期"
                              end-placeholder="结束日期"
                              @change="submitForm('filter')"
                            >
                            </el-date-picker>
                          </div>
                        </div>
                      </el-popover>
                    </template>
                  </el-table-column>
                  <el-table-column
                    v-else-if="h.prop == 'status'"
                    prop="status"
                    align="left"
                    min-width="100"
                    class-name="canEdit"
                  >
                    <template slot="header" slot-scope="scope">
                      <span class="header-label" id="status">状态</span>
                      <div
                        class="sortL"
                        v-if="!filter.ownProject"
                        :class="{
                          ascing: sortType == 'status' && sortCheck == 1,
                          descing: sortType == 'status' && sortCheck == 2,
                        }"
                      >
                        <i
                          class="asc"
                          @click.stop="sortWay('asc', 'status')"
                        ></i>
                        <i
                          class="desc"
                          @click.stop="sortWay('desc', 'status')"
                        ></i>
                      </div>
                      <el-popover
                        title="搜索"
                        popper-class="popperEdit"
                        placement="bottom"
                        trigger="click"
                        v-if="!filter.ownProject"
                      >
                        <i
                          slot="reference"
                          class="heardSearch el-icon-search"
                        ></i>
                        <div class="popcon">
                          <div class="popcon-input">
                            <el-select
                              v-model="filter.statusList"
                              filterable
                              multiple
                              collapse-tags
                              @change="submitForm('filter')"
                              placeholder="请选择"
                            >
                              <el-option
                                v-for="(item, index) in projectType"
                                :key="'b_' + index"
                                filterable
                                collapse-tags
                                :value="item.value"
                                :label="item.value"
                              >
                              </el-option>
                            </el-select>
                          </div>
                        </div>
                      </el-popover>
                    </template>
                    <template slot-scope="scope">
                      <div>
                        <div class="tableType">
                          <span
                            class="tag"
                            :class="{
                              tag02: scope.row.status == '进行中',
                              tag03: scope.row.status == '成功',
                              tag05: scope.row.status == '失败',
                              tag06: scope.row.status == '暂停',
                              tag07: scope.row.status == '取消',
                            }"
                            >{{ scope.row.status }}</span
                          >
                        </div>
                        <el-popover
                          placement="bottom"
                          title="编辑"
                          popper-class="popperEdit"
                          @show="getInfoById(scope.row.id)"
                          trigger="click"
                        >
                          <i
                            slot="reference"
                            v-if="scope.row.canView"
                            class="el-icon-edit tabHref"
                          ></i>
                          <div class="popcon">
                            <div class="popcon-input">
                              <el-select
                                filterable
                                v-model="project.status"
                                placeholder="请选择"
                              >
                                <el-option
                                  v-for="(item, index) in projectType"
                                  :key="'c_' + index"
                                  :value="item.value"
                                  :label="item.value"
                                >
                                </el-option>
                              </el-select>
                              <div style="margin-top: 10px">
                                <el-select
                                  v-if="project.status == '失败'"
                                  filterable
                                  v-model="project.failRemark"
                                  placeholder="请选择失败原因"
                                >
                                  <el-option
                                    v-for="(item, index) in projectFailType"
                                    :key="'c_F_' + index"
                                    :value="item.val"
                                    :label="item.name"
                                  >
                                  </el-option>
                                </el-select>
                              </div>
                            </div>
                            <div class="subBtn">
                              <el-button
                                size="mini"
                                @click="cancel"
                                icon="el-icon-close"
                              ></el-button
                              ><el-button
                                type="primary"
                                size="mini"
                                icon="el-icon-check"
                                @click="checkVal"
                              ></el-button>
                            </div>
                          </div>
                        </el-popover>
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column
                    v-else-if="h.prop == 'countJoinProject'"
                    min-width="120"
                    class-name="canEdit"
                    prop="countJoinProject"
                  >
                    <template slot="header" slot-scope="scope">
                      <span class="header-label" id="countJoinProject"
                        >加入项目量</span
                      >
                    </template>
                    <template slot-scope="scope">
                      <span
                        :class="scope.row.countJoinProject ? 'tabHref' : ''"
                        @click="
                          scope.row.countJoinProject
                            ? detailsPop(scope.row.id, 'join')
                            : ''
                        "
                        v-if="scope.row.canView"
                        >{{ scope.row.countJoinProject }}</span
                      >
                      <el-tooltip content="无法查看保密项目">
                        <span v-if="!scope.row.canView">*</span>
                      </el-tooltip>
                    </template>
                  </el-table-column>
                  <el-table-column
                    v-else-if="h.prop == 'countResumeRecommend'"
                    min-width="120"
                    class-name="canEdit"
                    prop="countResumeRecommend"
                  >
                    <template slot="header" slot-scope="scope">
                      <span class="header-label" id="countResumeRecommend"
                        >简历推荐量</span
                      >
                    </template>
                    <template slot-scope="scope">
                      <span
                        :class="scope.row.countResumeRecommend ? 'tabHref' : ''"
                        @click="
                          scope.row.countResumeRecommend
                            ? detailsPop(scope.row.id, 'recommend')
                            : ''
                        "
                        v-if="scope.row.canView"
                        >{{ scope.row.countResumeRecommend }}</span
                      >
                      <el-tooltip content="无法查看保密项目">
                        <span v-if="!scope.row.canView">*</span>
                      </el-tooltip>
                    </template>
                  </el-table-column>
                  <el-table-column
                    min-width="120"
                    v-else-if="h.prop == 'countClientInterview'"
                    class-name="canEdit"
                    prop="countClientInterview"
                  >
                    <template slot="header" slot-scope="scope">
                      <span class="header-label" id="countClientInterview"
                        >客户面试量</span
                      >
                    </template>
                    <template slot-scope="scope">
                      <span
                        :class="scope.row.countClientInterview ? 'tabHref' : ''"
                        @click="
                          scope.row.countClientInterview
                            ? detailsPop(scope.row.id, 'interview')
                            : ''
                        "
                        v-if="scope.row.canView"
                        >{{ scope.row.countClientInterview }}</span
                      >
                      <el-tooltip content="无法查看保密项目">
                        <span v-if="!scope.row.canView">*</span>
                      </el-tooltip>
                    </template>
                  </el-table-column>
                  <el-table-column
                    v-else-if="h.prop == 'countOffer'"
                    min-width="120"
                    class-name="canEdit"
                    prop="countOffer"
                  >
                    <template slot="header" slot-scope="scope">
                      <span class="header-label" id="countOffer">Offer量</span>
                    </template>
                    <template slot-scope="scope">
                      <span
                        :class="scope.row.countOffer ? 'tabHref' : ''"
                        @click="
                          scope.row.countOffer
                            ? detailsPop(scope.row.id, 'offer')
                            : ''
                        "
                        v-if="scope.row.canView"
                        >{{ scope.row.countOffer }}</span
                      >
                      <el-tooltip content="无法查看保密项目">
                        <span v-if="!scope.row.canView">*</span>
                      </el-tooltip>
                    </template>
                  </el-table-column>
                  <el-table-column
                    v-else-if="h.prop == 'countEntry'"
                    min-width="120"
                    class-name="canEdit"
                    prop="countEntry"
                  >
                    <template slot="header" slot-scope="scope">
                      <span class="header-label" id="countEntry">入职量</span>
                    </template>
                    <template slot-scope="scope">
                      <span
                        :class="scope.row.countEntry ? 'tabHref' : ''"
                        @click="
                          scope.row.countEntry
                            ? detailsPop(scope.row.id, 'countEntry')
                            : ''
                        "
                        v-if="scope.row.canView"
                        >{{ scope.row.countEntry }}</span
                      >
                      <el-tooltip content="无法查看保密项目">
                        <span v-if="!scope.row.canView">*</span>
                      </el-tooltip>
                    </template>
                  </el-table-column>
                  <el-table-column
                    v-else-if="h.prop == 'countSuccess'"
                    min-width="120"
                    class-name="canEdit"
                    prop="countSuccess"
                  >
                    <template slot="header" slot-scope="scope">
                      <span class="header-label" id="countSuccess">成功量</span>
                    </template>
                    <template slot-scope="scope">
                      <span
                        :class="scope.row.countSuccess ? 'tabHref' : ''"
                        @click="
                          scope.row.countSuccess
                            ? detailsPop(scope.row.id, 'success')
                            : ''
                        "
                        v-if="scope.row.canView"
                        >{{ scope.row.countSuccess }}</span
                      >
                      <el-tooltip content="无法查看保密项目">
                        <span v-if="!scope.row.canView">*</span>
                      </el-tooltip>
                    </template>
                  </el-table-column>
                  <el-table-column
                    v-else-if="h.prop == 'originalHireCount'"
                    min-width="120"
                    class-name="canEdit"
                    prop="originalHireCount"
                  >
                    <template slot="header" slot-scope="scope">
                      <span class="header-label" id="originalHireCount"
                        >需求数量</span
                      >
                    </template>
                    <template slot-scope="scope">
                      {{
                        scope.row.originalHireCount
                          ? scope.row.originalHireCount
                          : 0
                      }}
                    </template>
                  </el-table-column>
                </template>
                <el-table-column
                  min-width="70"
                  v-if="
                    hasMenuPage('progream:automaticMatching') ||
                    hasMenuPage('progream:copy')
                  "
                >
                  <template slot="header" slot-scope="scope"> 操作 </template>
                  <template slot-scope="scope">
                    <span
                      class="tabHref"
                      @click="addNew(scope.row.id)"
                      v-if="hasMenuPage('progream:copy')"
                      id="projectCopy"
                    >
                      复制
                    </span>
                  </template>
                </el-table-column>
              </el-table>
              <el-pagination
                :hide-on-single-page="filter.page.total > 10 ? false : true"
                v-if="filter.page.total > 0"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="filter.page.current"
                :page-size="filter.page.size"
                :pager-count="5"
                layout="total,prev, pager, next,sizes"
                :total="filter.page.total"
                :page-sizes="[10, 20, 50, 100]"
                style="margin-bottom: 20px"
              ></el-pagination>
            </el-col>
          </el-row>
        </el-col>
      </section>
    </el-row>
    <el-drawer
      :visible.sync="drawerVisible"
      custom-class="drawerClass"
      direction="ttb"
      :modal="false"
      append-to-body
      :before-close="handleClose"
    >
      <div slot="title" class="drawerTitle">
        {{ drawerTitle }}
      </div>
      <div class="drawerCon">
        <addore-edit
          v-if="drawerVisible"
          @loadList="loadList"
          @toInfo="detailsPop"
          :projectId="projectId"
          :isCopy="isCopy"
        ></addore-edit>
      </div>
    </el-drawer>
    <el-dialog
      :visible.sync="orderSearchVisible"
      :modal-append-to-body="false"
      append-to-body
      title="订阅搜索"
      :close-on-click-modal="false"
      width="600px"
    >
      <el-form
        @submit.native.prevent
        :model="searchFrom"
        ref="searchFrom"
        style="padding: 0 50px"
        :rules="rules"
        :validate-on-rule-change="false"
        label-width="0"
        label-position="right"
      >
        <el-form-item prop="name">
          <el-input
            placeholder="请给订阅搜索取个名字"
            v-model.trim="searchFrom.name"
            maxlength="12"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button
          @click="
            resetForm('searchFrom');
            orderSearchVisible = false;
          "
          size="small"
          >取 消</el-button
        >
        <el-button
          type="primary"
          size="small"
          :loading="isSubscribeSearch"
          @click="submitOrder('searchFrom')"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      :visible.sync="proNameVisible"
      :modal-append-to-body="false"
      append-to-body
      title="编辑"
      :close-on-click-modal="false"
      width="600px"
      :before-close="closeDialog"
    >
      <el-form
        @submit.native.prevent
        :model="project"
        ref="project"
        :validate-on-rule-change="false"
        label-width="100px"
      >
        <p class="DialogTips">
          <i class="el-icon-warning"></i
          >项目名称是由‘职位+公司简称’自动生成的，您可以在此处修改职位名称
        </p>
        <el-form-item
          style="margin-bottom: 10px"
          prop="position"
          label="职位名称："
        >
          <el-input
            placeholder="请输入新的职位名称"
            v-model.trim="project.position"
            maxlength="50"
            id="projectPosition"
            @focus="showNewProject = true"
          ></el-input>
        </el-form-item>
        <el-form-item label="" style="margin: 0" v-if="showNewProject">
          <p style="font-size: 13px; color: #606266; line-height: 1.5">
            预览新项目名称：{{
              project.position + "-" + project.commonCompanyName
            }}
          </p>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button
          @click="
            proNameVisible = false;
            showNewProject = false;
          "
          id="proNameVisibleCencle"
          size="small"
          >取 消</el-button
        >
        <el-button
          type="primary"
          size="small"
          @click="checkVal('project')"
          id="proNameVisibleSave"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      :visible.sync="fileManagement"
      :modal-append-to-body="false"
      append-to-body
      @close="updateFavoriteList"
      title="文件夹管理"
      :close-on-click-modal="false"
      width="720px"
    >
      <file
        :type="type"
        @getFavoriteData="childByValue"
        @length="getLength"
        :checkbox="isCheckbox"
      ></file>
      <span
        slot="footer"
        class="dialog-footer"
        v-if="isCheckbox.edit && isShowSaveBtn"
      >
        <el-button
          size="mini"
          :disabled="favoriteList.favoriteIds.length == 0"
          :loading="fileManagementloading"
          @click="saveFavorite"
          type="primary"
          >保存</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  getProjectPageList,
  getListFavorite,
  addToFavorite, //加入文件夹
  getUserBaseInfos, //获取用户列表
  getProjectById, //获取编辑详情
  addOrEditProject, //编辑保存
  SearchAddOrEdit, //新增-编辑订阅管理
  GetSearchPage, //获取订阅管理列表
  GetSearchById, //根据ID获取方案
  deleteSearchById, //通过id删除方案
  secureProject, //批量设为保密
  hasSecureProject, //判断当前用户是否有设为保密项目的权限
  cancelSecure, //取消保密
  exportProjectList, //导出
  emailExportProjectList, //email导出
  canOperate,
  removeFromFavorite,
  permissionSecure, //是否有项目的查看权限
  treeList,
  cityTree,
  projectProjectSetHeaders, //自定义项目列表表头
  projectProjectGetHeaders, //获取自定义项目列表表头
} from "../../api/api";
import {
  statisticsResumePositionAutomatic, //记录人岗自动匹配-自动匹配人才点击量
  statisticsClickFunctionEscape, // 记录功能或页面跳出
  statisticsClickProjectAdd, //记录点击新增项目
} from "../../api/buryingPoint";
import { mapGetters } from "vuex";
import file from "../../components/file/file";
import addoreEdit from "./../../components/project/addorEdit";
import * as data from "../../common/json/commData.json";
import { ApiBaseUrl } from "../../api/http";
import { composedPath } from "../../common/utils";
export default {
  name: "customer",
  components: { addoreEdit, file },
  data() {
    return {
      proActived: "2",
      baseUrl: ApiBaseUrl,
      proNameVisible: false,
      showNewProject: false,
      OrderSearchList: [],
      orderSearchVisible: false,
      isShowSaveBtn: false,
      favoriteList: {
        favoriteIds: [],
        itemIds: [],
      },
      isCheckbox: {
        val: true,
        edit: false,
      },
      searchFrom: {
        id: null,
        name: "",
        searchList: {},
        type: "项目",
        version: 0,
      },
      rules: {
        name: [
          { required: true, trigger: "blur", message: "请给订阅搜索取个名字" },
        ],
      },
      type: "项目",
      showMore: false,
      dialogVisible: false,
      specialItems: [],
      fuzzyItems: [],
      creditCode: "",
      fileManagement: false,
      fileManagementloading: false,
      activeNames: ["1", "2", "3"],
      activeNames1: ["1", "2"],
      shareData: [],
      myFileData: [],
      actived: "进行中",
      menuList: [
        {
          name: "我的项目（进行中）",
          val: "进行中",
        },
        {
          name: "我的项目（成功）",
          val: "成功",
        },
        {
          name: "我的项目（暂停）",
          val: "暂停",
        },
        {
          name: "我的项目（失败）",
          val: "失败",
        },
        {
          name: "我的项目（取消）",
          val: "取消",
        },
        {
          name: "所有项目",
          val: "all",
        },
      ],
      projectId: "", //项目id
      showSearchTab: false,
      projectType: data.projectType,
      multipleSelection: [],
      listLoading: false,
      isDisableDel: true,
      delFiles: {
        favoriteId: "",
        itemIds: [],
      },
      filter: {
        filter: "",
        ids: [],
        id: null,
        favoriteId: "",
        name: "",
        companyId: null,
        managerIds: [],
        createdEndTime: "",
        createdStartTime: "",
        lastOperateStartTime: "",
        lastOperateEndTime: "",
        ownProject: false,
        participantIds: [],
        statusList: [],
        userId: null,
        page: {
          asc: [],
          ascs: [],
          countId: "",
          desc: [],
          descs: [],
          hitCount: true,
          maxLimit: null,
          optimizeCountSql: true,
          orders: [
            {
              asc: false,
              column: "lastOperateTime",
            },
          ],
          current: 1,
          size: 10,
          searchCount: true,
          pages: null,
          records: [],
          total: 0,
        },
      },
      project: {
        status: "",
        managerId: [],
        positionCompanyName: "",
        failRemark: "",
        permissionUserIds: [],
      },
      isShowDelBtn: false,
      drawerVisible: false,
      HsearchList: [],
      ListData: [],
      userList: [],
      sortCheck: null,
      sortType: "",
      props: {
        value: "id",
        label: "name",
        children: "children",
        multiple: true,
      },
      showFavorBtn: false,
      rangTime: [],
      drawerTitle: "",
      timeVal: "",
      options2: [],
      participantIds: [],
      isSubscribeSearch: false,
      isCopy: false,
      projectFailType: data.projectFailType,
      progectTimeVal: "",
      isOperation: false,
      cityList: [],
      kd: Date.now(),
      target: {},
      tableHeaders: [
        { prop: "id", label: "项目ID" },
        { prop: "projectName", label: "项目名称" },
        { prop: "managerName", label: "项目经理" },
        { prop: "participants", label: "参与顾问" },
        { prop: "bdManagerName", label: "项目BD" },
        { prop: "cities", label: "城市" },
        { prop: "monthlySalary", label: "月薪" },
        { prop: "companyName", label: "客户公司" },
        { prop: "countResumePosition", label: "自动匹配人才" },
        { prop: "createdTime", label: "创建时间" },
        { prop: "lastOperateTime", label: "我的操作时间" },
        { prop: "maxProjectOperateTime", label: "项目操作时间" },
        { prop: "status", label: "状态" },
        { prop: "countJoinProject", label: "加入项目量" },
        { prop: "countResumeRecommend", label: "简历推荐量" },
        { prop: "countClientInterview", label: "客户面试量" },
        { prop: "countOffer", label: "Offer量" },
        { prop: "countEntry", label: "入职量" },
        { prop: "countSuccess", label: "成功量" },
        { prop: "originalHireCount", label: "需求数量" },
      ],
      headers: [
        { prop: "id", label: "项目ID" },
        { prop: "projectName", label: "项目名称" },
        { prop: "managerName", label: "项目经理" },
        { prop: "participants", label: "参与顾问" },
        { prop: "bdManagerName", label: "项目BD" },
        { prop: "cities", label: "城市" },
        { prop: "monthlySalary", label: "月薪" },
        { prop: "companyName", label: "客户公司" },
        { prop: "countResumePosition", label: "自动匹配人才" },
        { prop: "createdTime", label: "创建时间" },
        { prop: "lastOperateTime", label: "我的操作时间" },
        { prop: "maxProjectOperateTime", label: "项目操作时间" },
        { prop: "status", label: "状态" },
        { prop: "countJoinProject", label: "加入项目量" },
        { prop: "countResumeRecommend", label: "简历推荐量" },
        { prop: "countClientInterview", label: "客户面试量" },
        { prop: "countOffer", label: "Offer量" },
        { prop: "countEntry", label: "入职量" },
        { prop: "countSuccess", label: "成功量" },
        { prop: "originalHireCount", label: "需求数量" },
      ],
      checkList: [],
      isOpen: false,
    };
  },
  computed: {
    ...mapGetters({
      collapsed: "upDateCollapsed",
    }),
  },
  created() {
    this.startTimeStamp = Math.round(new Date().getTime() / 1000);
    if (!this.$route.query.order && !this.$route.query.folder) {
      if (this.$route.query.ownProject) {
        //带参刷新
        this.filter.statusList = [];
        this.filter.ownProject = eval(this.$route.query.ownProject);
        if (this.$route.query.status !== "all") {
          this.filter.statusList.push(this.$route.query.status);
        }
        this.actived = this.$route.query.status;
      } else {
        //初始默认
        this.filter.ownProject = true;
        this.filter.statusList = ["进行中"];
      }
      this.isDisableDel = false;
      this.isShowDelBtn = false;
    } else {
      this.actived = this.$route.query.order || this.$route.query.folder;
      if (this.$route.query.order) {
        //订阅搜索
        this.isDisableDel = false;
        this.isShowDelBtn = false;
        GetSearchById(this.actived).then((res) => {
          if (res.success) {
            this.filter = res.result.searchList;
          }
        });
      }
      if (this.$route.query.folder) {
        //文件夹
        this.filter.favoriteId = this.actived;
        this.delFiles.favoriteId = this.actived;
        this.isDisableDel = true;
        this.isShowDelBtn = true;
      }
    }
    this.loadOrderSearchList();
    this.loadList();
    this.loadUserBase();
    this.myFileList();
    this.permissionTree();
    this.LoadColumnList();
  },
  watch: {
    drawerVisible(newValue) {
      if (newValue) {
        this.noScroll(); //禁止滚动
      } else {
        this.canScroll();
      }
    },
    "project.companyIndustryIds"(newValue, oldValue) {
      if (newValue.length > 5) {
        this.$message({
          message: "最多不超过5个",
          duration: 1500,
          type: "error",
        });
        this.$nextTick(() => {
          this.project.companyIndustryIds = oldValue;
        });
      }
    },
    "$route.query"(newValue, oldValue) {
      if (!newValue.order && !newValue.folder) {
        if (newValue.ownProject) {
          this.filter.ownProject = eval(newValue.ownProject);
          this.actived = newValue.status;
          this.filter.statusList = [];
          if (newValue.status !== "all") {
            this.filter.statusList.push(newValue.status);
          }
        } else {
          this.actived = "进行中";
          this.filter.ownProject = true;
          this.filter.statusList = ["进行中"];
        }
        this.isDisableDel = false;
        this.isShowDelBtn = false;
        this.loadList();
      } else {
        //订阅&&文件夹
        this.actived = newValue.order || newValue.folder;
        if (newValue.order) {
          this.isDisableDel = false;
          this.isShowDelBtn = false;
          GetSearchById(this.actived).then((res) => {
            if (res.success) {
              this.filter = res.result.searchList;
              this.loadList();
            }
          });
        }
        if (newValue.folder) {
          //文件夹
          this.filter.favoriteId = this.actived;
          this.delFiles.favoriteId = this.actived;
          this.isDisableDel = true;
          this.isShowDelBtn = true;
          this.loadList();
        }
      }
    },
  },
  methods: {
    setCells() {
      this.listLoading = true;
      setTimeout(() => {
        let cells = document.querySelectorAll(".has-gutter th .cell");
        cells.forEach((cell, index) => {
          cell.index = index;
          cell.draggable = true;
          cell.addEventListener("dragover", (e) => {
            e.preventDefault();
          });
          cell.addEventListener("dragleave", (e) => {
            e.target.style.border = "none";
          });
          cell.addEventListener("drop", (e) => {
            e.preventDefault();
            const path = composedPath(e);
            let cell = path.find((p) => p.className === "cell");
            if (!cell) {
              return;
            }
            cell.style.border = "none";
            if (this.target !== cell) {
              let prop1 = this.target.children[0].id;
              let prop2 = cell.children[0].id;
              let index1 = this.tableHeaders.findIndex((h) => h.prop === prop1);
              let index2 = this.tableHeaders.findIndex((h) => h.prop === prop2);

              let temp = this.tableHeaders[index1];
              this.tableHeaders[index1] = this.tableHeaders[index2];
              this.tableHeaders[index2] = temp;
              //TODO 实现表格顺序的存储
              this.setProjectProjectSetHeaders();
            }
          });
          cell.addEventListener("dragenter", (e) => {
            const path = composedPath(e);
            let cell = path.find((p) => p.className === "cell");
            if (!cell) {
              return;
            }
            if (this.target !== cell) {
              setTimeout(() => {
                if (this.target.index > cell.index) {
                  cell.style.borderLeft = "1px solid red";
                } else {
                  cell.style.borderRight = "1px solid red";
                }
              }, 0);
            }
          });
          cell.addEventListener("dragstart", (e) => {
            // 如果是拖拽宽度就不触发
            if (document.body.style.cursor === "col-resize") {
              this.target = {};
              return;
            }
            setTimeout(() => {
              e.stopPropagation();
              if (this.target.toString() === "{}") {
                cell.style.backgroundColor = "#eee";
              }
              this.target = e.target;
            }, 0);
          });
          cell.addEventListener("dragend", (e) => {
            cell.style.backgroundColor = "#f5f7fa";
            e.target.style.border = "none";
          });
          cell.addEventListener("mousedown", (e) => {});
        });
      }, 0);
    },
    setProjectProjectSetHeaders(tableHeaders) {
      projectProjectSetHeaders({
        customHeaders: tableHeaders ? tableHeaders : this.tableHeaders,
      }).then((res) => {
        if (res.success) {
          this.$message.success("操作成功");
          this.loadList();
        }
        this.listLoading = false;
      });
    },
    LoadColumnList() {
      //加载自定义列表
      this.cityList = [];
      cityTree().then((res) => {
        if (res.success) {
          this.cityList = res.result;
        }
      });
    },
    // 小类
    permissionTree() {
      treeList().then((res) => {
        if (res.success) {
          this.options2 = res.result;
        }
      });
    },
    showProEdit(id, type) {
      this.getInfoById(id, type);
    },
    closeDialog() {
      this.proNameVisible = false;
      this.showNewProject = false;
    },
    addToFavorite() {
      this.isOperation = true;
      if (this.multipleSelection && this.multipleSelection.length > 0) {
        this.fileManagement = true;
        this.isCheckbox.edit = true;
      } else {
        this.$message.error("请先选择项目");
      }
    },
    getLength(val) {
      if (val) {
        this.isShowSaveBtn = true;
      } else {
        this.isShowSaveBtn = false;
      }
    },
    myFileList() {
      getListFavorite({ type: this.type }).then((res) => {
        this.shareData = res.result.shareList;
        this.myFileData = res.result.myList;
      });
    },
    updateFavoriteList() {
      this.myFileList();
    },
    goProjectList(id) {},
    saveFavorite() {
      this.fileManagementloading = true;
      addToFavorite(this.favoriteList).then((res) => {
        this.fileManagement = false;
        this.fileManagementloading = false;
        this.$message.success("操作成功");
      });
    },
    childByValue(childValue) {
      this.favoriteList.favoriteIds = childValue;
    },
    downExport() {
      //导出
      this.isOperation = true;
      let setData = false;
      let isconfirm = true;
      var int = setTimeout(function () {
        let that = this;
        setData = true;
        clearInterval(int);
        let div =
          "<p style='font-weight:bold'>后台处理中，稍后请在 " +
          "<a href='/tools/exportingRecords' style='color:#526ecc'>导出记录</a>" +
          " 获取文件</p>";
        if (isconfirm) {
          that.$globalHub
            .$confirm(div, "提示", {
              showCancelButton: false,
              showConfirmButton: false,
              customClass: "altCls",
              dangerouslyUseHTMLString: true,
            })
            .then(() => {})
            .catch(() => {});
        }
      }, 5000);
      exportProjectList(this.filter).then((res) => {
        if (res.success) {
          if (!setData) {
            isconfirm = false;
            location.href = this.baseUrl + res.result;
          }
        }
      });
    },
    emaildownExport() {
      //导出
      emailExportProjectList(this.filter).then((res) => {
        this.$message.success("提交成功，系统处理中");
      });
    },
    checkContral(e) {
      this.isOperation = true;
      let ids =
        this.multipleSelection && this.multipleSelection.length > 0
          ? this.multipleSelection.map((item) => item.id)
          : [];
      switch (e) {
        case 1:
          break;
        case 2: //批量设为保密
          this.patchSecureProject(ids);
          break;
        case 3: //批量取消保密
          this.patchCancelSecure(ids);
          break;
      }
    },
    patchSecureProject(ids) {
      //设为保密项目
      hasSecureProject().then((res) => {
        if (res.success) {
          if (res.result) {
            secureProject({ projectIds: ids }).then((reqs) => {
              if (reqs.success) {
                this.$message.success(reqs.message);
                this.loadList();
              }
            });
          } else {
            this.$message.error("暂无权限");
          }
        }
      });
    },
    patchCancelSecure(ids) {
      //取消保密项目
      cancelSecure({ projectIds: ids }).then((res) => {
        if (res.success) {
          this.$message.success("操作成功");
          this.loadList();
        }
      });
    },
    loadOrderSearchList() {
      GetSearchPage({ type: "项目" }).then((res) => {
        if (res.success) {
          this.OrderSearchList = res.result;
        }
      });
    },
    submitOrder(formName) {
      this.isSubscribeSearch = true;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.searchFrom.searchList = this.filter;
          SearchAddOrEdit(this.searchFrom).then((res) => {
            if (res.success) {
              this.$message.success("操作成功");
              this.orderSearchVisible = false;
              this.loadOrderSearchList();
              this.resetForm("searchFrom");
              this.isSubscribeSearch = false;
            }
          });
        } else {
          this.isSubscribeSearch = false;
          return false;
        }
      });
    },
    toEditInfo(id) {
      this.drawerTitle = "编辑项目";
      this.projectId = id;
    },
    tuComInfobyId(id) {
      this.drawerTitle = "项目";
    },
    detailsPop(id, type) {
      this.isOperation = true;
      if (type == "automatic") {
        statisticsResumePositionAutomatic({
          entry: "项目列表",
          projectId: id,
        }).then();
      }
      permissionSecure({ id: id }).then((res) => {
        if (res.success) {
          if (res.result) {
            let newUrl = this.$router.resolve({
              path: "/project/details",
              query: { id: id, type: type, entry: "项目列表" },
            });
            window.open(newUrl.href, "_blank");
          } else {
            this.$message.error("暂无查看权限");
          }
        }
      });
    },
    getSearch(val) {
      this.filter.participantIds = this.participantIds.map((i) => {
        return i[i.length - 1];
      });
      this.submitForm();
    },
    submitForm() {
      this.isOperation = true;
      if (this.filter.id && this.filter.id < 1) {
        this.$message.error("请输入正确的id");
        return false;
      }
      if (this.timeVal && this.timeVal.length > 0) {
        this.filter.createdStartTime = this.timeVal[0];
        this.filter.createdEndTime = this.timeVal[1];
      } else {
        this.filter.createdStartTime = "";
        this.filter.createdEndTime = "";
      }
      if (this.progectTimeVal && this.progectTimeVal.length > 0) {
        this.filter.lastModifiedStartTime = this.progectTimeVal[0];
        this.filter.lastModifiedEndTime = this.progectTimeVal[1];
      } else {
        this.filter.lastModifiedStartTime = "";
        this.filter.lastModifiedEndTime = "";
      }
      if (this.rangTime && this.rangTime.length > 0) {
        this.filter.lastOperateStartTime = this.rangTime[0];
        this.filter.lastOperateEndTime = this.rangTime[1];
      } else {
        this.filter.lastOperateStartTime = "";
        this.filter.lastOperateEndTime = "";
      }
      this.filter.page.current = 1;
      this.loadList();
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    cancel() {
      document.body.click();
    },
    getTime(val) {
      this.timeVal = val;
      this.getSearch();
    },
    //项目操作时间
    getProgectTime(val) {
      this.progectTimeVal = val;
      this.getSearch();
    },

    loadList(name) {
      window.scrollTo(0, 0);
      this.listLoading = true;
      if (this.drawerTitle != "项目") {
        this.drawerVisible = false;
      }
      if (name) {
        //详情关闭文件夹弹窗
        this.myFileList();
      }
      projectProjectGetHeaders().then((res) => {
        if (res.success) {
          this.tableHeaders = res.result;
        }
      });
      getProjectPageList(this.filter).then((res) => {
        if (res.success) {
          this.ListData = res.result.records;
          if (this.filter.favoriteId && this.ListData.length) {
            this.isShowDelBtn = true;
          }
          this.ListData.forEach((element) => {
            element["show"] = false;
          });
          this.filter.page.total = res.result.total;
        }
        this.listLoading = false;
      });
    },
    loadUserBase() {
      //获取用户列表
      getUserBaseInfos(this.filter).then((res) => {
        if (res.success) {
          this.userList = res.result;
        }
      });
    },
    delSearch(id) {
      //删除订阅搜索
      this.$confirm("确定删除该订阅?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        customClass: "altCls",
        type: "warning",
      })
        .then(() => {
          deleteSearchById(id).then((res) => {
            if (res.success) {
              this.$message.success("操作成功");
              this.loadOrderSearchList();
              if (this.actived == id) {
                this.reset();
                this.$router.push({
                  path: "/project",
                  query: {
                    ownProject: true,
                    status: "进行中",
                  },
                });
                this.loadList();
              }
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    //新增/复制客户
    addNew(id) {
      this.isOperation = true;
      if (id) {
        this.projectId = id;
        this.isCopy = true;
      } else {
        this.projectId = null;
        statisticsClickProjectAdd({
          entry: "项目页面",
        }).then();
      }
      this.drawerVisible = true;
      this.drawerTitle = "新增项目";
    },
    handleClose(done) {
      this.isCopy = false;
      this.projectId = null;
      if (this.drawerTitle == "编辑项目") {
        this.drawerTitle = "项目";
      } else {
        this.drawerTitle = "";
        this.drawerVisible = false;
      }
    },
    orderSearch() {
      //订阅搜索
      this.orderSearchVisible = true;
    },
    sortWay(name, type) {
      //升降序
      this.sortType = type;
      this.sortCheck = null;
      this.filter.page.orders[0].column = type;
      if (name == "asc") {
        this.sortCheck = this.sortCheck == 1 ? null : 1;
        this.filter.page.orders[0].asc = true;
      }
      if (name == "desc") {
        this.sortCheck = this.sortCheck == 2 ? null : 2;
        this.filter.page.orders[0].asc = false;
      }
      if (this.sortCheck == null) {
        this.filter.page.orders[0].asc = "";
        this.filter.page.orders[0].asc = "";
        this.filter.page.orders[0].column = "";
      }
      this.loadList();
    },
    getInfoById(id, type) {
      //获取编辑详情
      this.projectId = id;
      canOperate({ id: id }).then((reqs) => {
        if (reqs.success) {
          if (reqs.result || this.getStore("permiss").includes("progream:edit")) {
            getProjectById(id).then((res) => {
              if (res.success) {
                if (type) {
                  this.proNameVisible = true;
                }
                this.project = res.result;
                this.$set(this.project, "companyIndustryIds", []);
                if (
                  res.result.companyIndustryList &&
                  res.result.companyIndustryList.length > 0
                ) {
                  res.result.companyIndustryList.forEach((item, index) => {
                    if (item.industryIds) {
                      this.project.companyIndustryIds[index] = eval(
                        item.industryIds
                      );
                    }
                  });
                }
              }
            });
          } else {
            this.cancel();
            this.$alert("您不是项目参与者，无法编辑", "提示", {
              showConfirmButton: false,
              customClass: "altCls",
            });
          }
        }
      });
    },
    checkVal() {
      //修改内容
      if (this.project.positionCompanyName == "") {
        this.$message.error("修改内容不能为空");
        return false;
      }
      if (this.project.status == "失败") {
        if (this.project.failRemark == "" || !this.project.failRemark) {
          this.$message.error("失败原因不能为空");
          return false;
        }
      }
      this.project.managerName = this.userList.filter((item) => {
        return item.id == this.project.managerId;
      })[0].name;
      this.project.newProjectManagerName = this.project.newprojectManagerId
        ? this.userList.filter((item) => {
            return item.id == this.project.newprojectManagerId;
          })[0].name
        : "";
      let perList = [];
      this.userList.forEach((item) => {
        this.project.permissionUserIds.forEach((i) => {
          if (item.id == i) {
            perList.push(item.name);
          }
        });
      });
      this.project.permissionUserNames = perList.join("、");
      addOrEditProject(this.project).then((res) => {
        if (res.success) {
          this.$message.success("操作成功");
          document.body.click();
          this.loadList();
          this.proNameVisible = false;
        }
      });
    },
    editFavorite() {
      //编辑收藏夹
      this.fileManagement = true;
      this.isCheckbox.edit = false;
    },
    removeFavorite() {
      //移出收藏夹
      this.$confirm("确定移出选中项?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        customClass: "altCls",
        type: "warning",
      })
        .then(() => {
          removeFromFavorite(this.delFiles).then((res) => {
            if (res.success) {
              this.$message.success("操作成功");
              this.loadList(this.HsearchList);
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    handleSizeChange(val) {
      //pageSize 改变时会触发
      this.filter.page.size = val;
      this.filter.page.current = 1;
      this.loadList();
    },
    handleCurrentChange(val) {
      //currentPage 改变时会触发
      this.filter.page.current = val;
      this.loadList();
    },
    handleChange() {},
    reset() {
      this.filter = {
        filter: "",
        ids: [],
        id: null,
        name: "",
        managerIds: [],
        createdEndTime: "",
        createdStartTime: "",
        ownProject: false,
        participantIds: [],
        statusList: [],
        userId: null,
        page: {
          asc: [],
          ascs: [],
          countId: "",
          desc: [],
          descs: [],
          hitCount: true,
          maxLimit: null,
          optimizeCountSql: true,
          orders: [
            {
              asc: false,
              column: "lastOperateTime",
            },
          ],
          current: 1,
          size: 20,
          searchCount: true,
          pages: null,
          records: [],
          total: 0,
        },
      };
    },
    searchCus(val, type) {
      if (this.actived != val) {
        this.actived = val;
        this.sortType = "";
        this.sortCheck = null;
        switch (type) {
          case "quick": //快捷菜单
            this.reset();
            this.$router.push({
              path: "/project",
              query: {
                ownProject: val != "all" ? true : false,
                status: val,
              },
            });
            break;
          case "order":
            this.$router.push({
              path: "/project",
              query: {
                order: val,
              },
            });
            break;
          case "folder":
            this.reset();
            this.$router.push({
              path: "/project",
              query: {
                folder: val,
              },
            });

            break;
        }
      }
    },
    addFavorite() {
      this.createDate.itemIds = [];
      if (this.multipleSelection.length == 0) {
        this.$message.error("请先选择需要加入的项");
        return false;
      }
      this.favorite = true;
      this.titName = "加入收藏夹";
      this.createDate.favoriteId = "";
      this.loadFavorList("项目");
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
      if (val.length) {
        this.isDisableDel = false;
        this.delFiles.itemIds = val.map((i) => i.id);
      } else {
        this.isDisableDel = true;
      }
      this.favoriteList.itemIds = val.map((i) => i.id);
    },
    filterFun(data, value) {
      // 弹窗 - 拼音过滤
      return data.data.name.toLowerCase().indexOf(value.toLowerCase()) !== -1;
    },
    //打开
    open() {
      this.checkList = [];
      for (let i = 0; i < this.tableHeaders.length; i++) {
        this.checkList.push(this.tableHeaders[i].prop);
      }
    },
    // 应用
    confirm() {
      this.listLoading = true;
      if (this.checkList.length > 0) {
        let list = [];
        for (let i = 0; i < this.headers.length; i++) {
          for (let j = 0; j < this.checkList.length; j++) {
            if (this.checkList[j]) {
              if (this.headers[i].prop == this.checkList[j]) {
                list.push({
                  prop: this.headers[i].prop,
                  label: this.headers[i].label,
                });
              }
            } else {
              return;
            }
          }
        }
        this.isOpen = false;
        this.setProjectProjectSetHeaders(list);
      } else {
        this.$message.error("请选择列表项");
      }
    },
  },
  mounted() {
    this.setCells();
    this.noScroll(); //禁止滚动
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.superStep = from.meta.name;
    });
  },
  destroyed() {
    this.canScroll();
    if (!this.isOperation) {
      statisticsClickFunctionEscape({
        endTimeStamp: Math.round(new Date().getTime() / 1000),
        presentStep: "项目",
        startTimeStamp: this.startTimeStamp,
        nextStep: this.$route.meta.name,
        superStep: this.superStep,
      }).then();
    }
  },
};
</script>

<style lang="scss" scoped>
@import "../collapsed.less";
.searchM {
  margin-bottom: 5px;
  .el-form-item {
    float: left;
  }
}
.searchL {
  width: calc(100% - 162px);
}
.search {
  height: 35px;
  line-height: 35px;
  :deep(.el-input__inner) {
    height: 35px !important;
    line-height: 35px !important;
    border-color: #e60012 !important;
  }
  :deep(.el-input-group__append) {
    border-color: #e60012 !important;
    background-color: #e60012;
    height: 33px !important;
    line-height: 33px !important;
    color: #fff;
    font-weight: bold;
  }
  :deep(.el-input-group__append:hover) {
    background-color: #cc0000;
  }
}
.other {
  line-height: 35px;
  font-size: 13px;
  float: right;
  span {
    margin: 0;
    cursor: pointer;
    color: #606266;
    &:hover {
      color: #e60012;
    }
  }
  .order {
    padding: 0 8px;
    border-right: 1px solid #ececec;
  }
  .more {
    padding-left: 8px;
    i {
      margin-left: 8px;
    }
  }
}
.tableType {
  display: inline-block;
}
.itemCls {
  li {
    height: 32px;
    line-height: 32px;
    padding: 0 20px;
    // color: #333;
    cursor: pointer;
    &:hover {
      color: #e60012;
    }
  }
}
.configer3 {
  padding: 0 !important;
  .el-checkbox {
    display: block;
    margin-bottom: 10px;
    // line-height: 22px;
  }
  .el-checkbox__input.is-checked + .el-checkbox__label {
    color: #606266;
  }
  .select_check {
    background: white;
    height: 380px;
    position: relative;
    .kpiButton {
      // width: 100%;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      padding: 10px 0;
      text-align: right;
      border-top: 1px solid #ededed;
      background: #fff;
    }
  }
}
</style>

<style lang="scss">
.select_check {
  .el-checkbox {
    display: block;
    margin-bottom: 10px;
  }
  .el-checkbox-group {
    height: 320px;
    overflow-y: auto;
    padding: 10px 15px 0 15px;
  }
  .el-checkbox__input.is-checked + .el-checkbox__label {
    color: #606266;
  }
}
</style>